<template>
  <nuxt-page/>
</template>
<script setup>
const title = "MEET BNTO | The AI-powered Fashion Rental Subscription Service"
const description = "The AI-powered Fashion Rental Subscription Service.More style, less money, infinite inspiration.Fashion on Rotation - Rent 6 items from coveted global brands starting at $98/month.One subscription, limitless outfits. Purchase your favorites at up to 75% off.Get personalized fashion advice and expand your style horizons with BNTO AI, your personal stylist.Enjoy $20 bonus credits each month, redeemable on rental purchases. Earn more by reviewing products or sharing BNTO with friends.No late fees, free shipping and returns, guaranteed cleaning. Pause or cancel anytime."
useSeoMeta({
  title: title,
  ogTitle: title,
  description: description,
  ogDescription: description,
  ogImage: '/og-image.png',
  ogSiteName: "BNTO",
  ogLocale: "en-US",
  ogType: "website",
  ogUrl: useRequestURL().href,
  twitterCard: 'summary_large_image',
})
</script>
